import { unref, watch, ref } from 'vue';
import { useElementVisibility } from '@vueuse/core';

type UriType = string | (() => string);
export const useInfiniteScroll = (target, uri: UriType, toWatch: any[] = [], size = 12) => {
  const items = ref([]);
  const page = ref(undefined);
  const next = ref(undefined);
  const pending = ref(false);
  const error = ref(undefined);

  const targetIsVisible = useElementVisibility(target);

  const createUri = () => {
    let finalUri: string;
    if (typeof uri === 'function') {
      finalUri = uri();
    } else {
      finalUri = uri;
    }
    const urlObject = new URL(finalUri);
    const searchParams = new URLSearchParams(urlObject.search);
    if (page.value) {
      searchParams.set('page', String(page.value));
    }
    searchParams.set('size', String(size));

    urlObject.search = searchParams.toString();
    return urlObject.toString();
  };

  const handleData = (newData, shouldReplace) => {
    if (!newData) {
      return;
    }
    next.value = newData.next;

    const check = new Set();
    const existingValues = shouldReplace ? [] : items.value;
    items.value = [...existingValues, ...newData.items].filter((obj) => !check.has(obj.id) && check.add(obj.id));
  };

  const fetch = async (shouldReplace) => {
    try {
      pending.value = true;
      const data = await $fetch(createUri());
      handleData(data, shouldReplace);
    } catch (e) {
      console.log('Failed to fetch', e);
      error.value = e;
    }
    pending.value = false;
  };
  // deliberately not await, to allow lazy loading
  fetch(true);

  watch([page, ...toWatch], ([newPage], [oldPage]) => {
    let shouldReplace = false;
    if (newPage === oldPage) {
      page.value = undefined;
      next.value = undefined;
      shouldReplace = true;
    }

    fetch(shouldReplace);
  });

  watch(
    () => ({ visible: targetIsVisible.value, nftLength: items.value.length }),
    ({ visible }) => {
      const unrefTarget = unref(target) as HTMLElement;
      if (visible && unrefTarget) {
        const rect = unrefTarget.getBoundingClientRect();
        const realVisible =
          rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.left <= (window.innerWidth || document.documentElement.clientWidth) &&
          rect.bottom >= 0 &&
          rect.right >= 0;
        if (realVisible && next.value) {
          page.value = next.value;
          next.value = undefined;
        }
      }
    }
  );

  return { items, pending };
};
